import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction } from '@grapecity/wijmo.grid';

import '@grapecity/wijmo.styles/wijmo.css';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import './CustomerQuoteEntry.css';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';

import { toFloat } from 'utils/numberUtils';

// 価格の再計算モーダル
function ReCalcModal(props) {
  const restApi = useRestApi();
  const [show, setShow] = useState(false);

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});
  // 更新用の明細データ
  const [updatedDetails, setUpdatedDetails] = useState([]);
  // 画面表示用の明細データ
  const [quoteDetails, setQuoteDetails] = useState([]);
  // 再計算後の合計
  const [updatedTotal, setUpdatedTotal] = useState([]);

  useEffect(() => {
    if (props.params.show) {
      restApi.post(`/api/quote/recalc`, { quoteDetails: props.params.quoteDetails }, (data) => {
        // 更新後の金額が設定されているレコードのみに絞る
        let _recalcQuoteDetails = data.recalcQuoteDetails.filter((x) => x.UPD_KINGAKU);

        if (_recalcQuoteDetails.length == 0) {
          setDialog({
            type: 'info',
            message: '価格変更がある工事項目がありませんでした。',
          });
          return;
        }

        setQuoteDetails(_recalcQuoteDetails);

        // 更新用データを作成
        let _updatedDetails = data.recalcQuoteDetails
          .map((x) => ({
            ...x,
            TANKA: x.UPD_TANKA ?? x.TANKA,
            KINGAKU: x.UPD_KINGAKU ?? x.KINGAKU,
            GENKA_TANKA: x.UPD_GENKA_TANKA ?? x.GENKA_TANKA,
            GENKA_KINGAKU: x.UPD_GENKA_KINGAKU ?? x.GENKA_KINGAKU,
            KAKAKU_UPD_FLG: 'UPD_KINGAKU' in x ? 1 : 0,
          }))
          // 原価率を再計算
          .map((x) => ({ ...x, GENKA_RATE: toFloat(x.GENKA_TANKA / x.TANKA, 2) }));
        setUpdatedDetails(_updatedDetails);
        calcUpdatedTotal(_updatedDetails);
        setShow(true);
      });
    }
  }, [props.params]);

  // 再計算後の合計金額を計算
  const calcUpdatedTotal = (details) => {
    // 小計
    let shokei = details.reduce((a, x) => a + x.KINGAKU, 0);
    // 消費税
    let tax = Math.floor(shokei * 0.1);
    // 請求額
    let seikyu = shokei + tax;
    // 原価合計
    let genka_kingaku = details.reduce((a, x) => a + x.GENKA_KINGAKU, 0);
    // 粗利
    let arari = shokei - genka_kingaku;
    // 粗利率
    let arariritu = Math.floor((arari / shokei) * 100) / 100;

    // 集計結果を反映
    setUpdatedTotal([
      {
        ShoKei: shokei,
        ZeiKei: tax,
        SeikyuKei: seikyu,
        GenkaKei: genka_kingaku,
        ArariKei: arari,
        RiekiRitsu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);
  };

  // 閉じる処理
  const handleClose = () => {
    setShow(false);
  };

  // 確定処理
  const handleSave = () => {
    props.params.callback(updatedDetails);
    setShow(false);
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      // 変更後と変更前の値がことなる場合は
      if (
        ['UPD_TANKA', 'UPD_KINGAKU', 'UPD_GENKA_TANKA', 'UPD_GENKA_KINGAKU'].includes(
          col['binding']
        )
      ) {
        const isUpd = dataItem[col['binding']] != dataItem[col['binding'].replace('UPD_', '')];
        e.cell.style.color = isUpd ? '#150AF5' : '';
        e.cell.style.fontWeight = isUpd ? 'bold' : '';
      }
    }
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xl'} open={show}>
        <DialogTitle className="p-3">見積基盤再計算</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <div>
                ・単価、原価を以下の内容で変更します。よろしければ確定ボタンで更新してください。
              </div>
              <div className="table-scroll" md="12" style={{ height: '500px' }}>
                <FlexGrid
                  allowSorting={false}
                  itemsSource={quoteDetails}
                  selectionMode={'Row'}
                  headersVisibility={'Column'}
                  isReadOnly={true}
                  formatItem={formatItem}
                  updatedView={(grid) => {
                    grid.rows.forEach((ele) => {
                      ele.height = 24;
                    });
                  }}
                >
                  <FlexGridColumn
                    binding="KOUJI_SYU"
                    header="工事種別"
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="CONTENT" header="名称" width="1*"></FlexGridColumn>
                  <FlexGridColumn binding="SIYOU" header="仕様" width="1*"></FlexGridColumn>
                  <FlexGridColumn binding="SU" header="数量" width={50}></FlexGridColumn>
                  <FlexGridColumn binding="TANI" header="単位" width={50}></FlexGridColumn>
                  <FlexGridColumn
                    binding="TANKA"
                    header="単価"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="KINGAKU"
                    header="金額"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="GENKA_TANKA"
                    header="原価単価"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="GENKA_KINGAKU"
                    header="原価合計"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="UPD_TANKA"
                    header="変更後_単価"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="UPD_KINGAKU"
                    header="変更後_金額"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="UPD_GENKA_TANKA"
                    header="変更後_原価"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="UPD_GENKA_KINGAKU"
                    header="変更後_原価合計"
                    dataType="Number"
                    width={100}
                  ></FlexGridColumn>
                </FlexGrid>
              </div>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <div style={{ display: 'flex' }}>
            <div>
              <FlexGrid
                itemsSource={props.quoteTotal}
                selectionMode={'None'}
                headersVisibility={'Column'}
                isReadOnly={true}
                allowSorting={false}
              >
                <FlexGridColumn
                  binding="ShoKei"
                  header="小計"
                  format="c0"
                  width={88}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ZeiKei"
                  header="消費税"
                  format="c0"
                  width={75}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SeikyuKei"
                  header="請求金額"
                  format="c0"
                  width={88}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GenkaKei"
                  header="原価合計"
                  format="c0"
                  width={88}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ArariKei"
                  header="粗利益額"
                  format="c0"
                  width={88}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="RiekiRitsu"
                  header="利益率"
                  format="n2"
                  width={80}
                ></FlexGridColumn>
              </FlexGrid>
            </div>
            <div
              style={{
                width: '88px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SwapHorizOutlinedIcon style={{ fontSize: '38pt' }} />
            </div>
            <div>
              <FlexGrid
                itemsSource={updatedTotal}
                selectionMode={'None'}
                headersVisibility={'Column'}
                isReadOnly={true}
                allowSorting={false}
              >
                <FlexGridColumn
                  binding="ShoKei"
                  header="変更後_小計"
                  format="c0"
                  width={98}
                  cssClass="tanka_upd_cell"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ZeiKei"
                  header="変更後_消費税"
                  format="c0"
                  width={92}
                  cssClass="tanka_upd_cell"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SeikyuKei"
                  header="変更後_請求金額"
                  format="c0"
                  width={98}
                  cssClass="tanka_upd_cell"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GenkaKei"
                  header="変更後_原価合計"
                  format="c0"
                  width={98}
                  cssClass="tanka_upd_cell"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ArariKei"
                  header="変更後_粗利益額"
                  format="c0"
                  width={98}
                  cssClass="tanka_upd_cell"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="RiekiRitsu"
                  header="変更後_利益率"
                  format="n2"
                  width={86}
                  cssClass="tanka_upd_cell"
                ></FlexGridColumn>
              </FlexGrid>
            </div>
          </div>
          <Button className="btn-fill footer-button" variant="primary" onClick={handleSave}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      {/** 確認ダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default ReCalcModal;
