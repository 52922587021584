import * as wjcGrid from '@grapecity/wijmo.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import '@grapecity/wijmo.styles/wijmo.css';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';
import IconButtonCompnent from 'components/Input/IconButtonCompnent';
import { MessageModal } from 'components/modal/MessageModal';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';
import { Label } from 'reactstrap';
import QuoteBaseModal from 'views/CustomerQuoteEntry/QuoteBaseModal';
import StatusLableComponent from 'components/Input/StatusLableComponent';
import ReCalcModal from 'views/CustomerQuoteEntry/ReCalcModal';

function CustomerQuoteEntry() {
  const restApi = useRestApi();
  // 画面パラメタを取得
  const location = useLocation();
  // 履歴
  const navigate = useNavigate();
  // マスタ情報
  const master = useMaster();
  // マスタ情報
  const auth = useAuth();
  // グリッド
  const flexGrid = useRef();
  // 顧客情報
  const [client, setClient] = useState({
    CLIENT_CD: '',
    NAME_SEI_1: '',
    NAME_MEI_1: '',
  });

  // 工事情報
  const [constraction, setConstraction] = useState({});

  // ボタンのアクション
  const action = {
    '': { caption: '', cmd: '', variant: '', readOnly: false },
    add: { caption: '登録', cmd: 'add', variant: 'primary', readOnly: false }, // 新規登録
    upd: { caption: '更新', cmd: 'upd', variant: 'primary', readOnly: false }, // 編集
    del: { caption: '削除', cmd: 'del', variant: 'danger', readOnly: true }, // 削除
    cpy: { caption: '登録', cmd: 'add', variant: 'primary', readOnly: false }, // コピー
    los: { caption: '失注', cmd: 'upd', variant: 'danger', readOnly: false }, // 失注
    approval: { caption: '承認', cmd: 'upd', variant: 'primary', readOnly: true }, // 承認
    request: { caption: '承認申請', cmd: 'upd', variant: 'primary', readOnly: false }, // 承認申請
    cancel: { caption: '申請取消', cmd: 'upd', variant: 'danger', readOnly: true }, // 申請取消
  };
  // モード
  const [mode, setMode] = useState('');

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 戻り先
  const [backPage, setBackPage] = useState();

  // 工事情報を表示するか？
  const [displayInfo, setDisplayInfo] = useState(true);

  // 集計行のマージ処理
  class CustomMergeManager extends wjcGrid.MergeManager {
    getMergedRange(panel, r, c, clip = true) {
      var rng = new wjcGrid.CellRange(r, c);
      let dataItem = panel?.grid?.rows[r]?.dataItem;
      if (panel.cellType == CellType.Cell && dataItem && dataItem['Group']) {
        if (c < 4) {
          rng.col = 0;
          rng.col2 = 4;
          return rng;
        }
        if (c > 5 && c < 8) {
          rng.col = 6;
          rng.col2 = 8;
          return rng;
        }
      }
      return null;
    }
  }

  // モーダルを表示・非表示
  const [show, setShow] = useState(false);

  // 明細登録モーダルへのパラメタ
  const [paramBaseQuote, setParamBaseQuote] = useState({});

  // 工事見積ヘッダ
  const [quoteHeader, setQuoteHeader] = useState({
    STATUS: '',
    KOUJI_MEI: '',
    KOUJI_BASYO: '',
    KOUKI: '',
    MITSU_DATE: format(new Date(), 'yyyy/MM/dd'),
    MITSU_KIGEN: '２ヵ月',
    ZUMEN_NO: '',
    BIKO: '工事中お客様の電気・水道を使用させていただきます。\n予めご了承ください。',
    TAN_CD: auth.auth.user_id,
    BIKO_SYANAI: '',
  });
  // 工事見積明細データ
  const [quoteDetails, setQuoteDetails] = useState([]);

  // 工事見積明細集計データ
  const [quoteTotal, setQuoteTotal] = useState([]);

  // 工事見積明細データ（表示用）
  const [viewQuoteDetails, setViewQuoteDetails] = useState([]);

  // マウスホバー中の項目
  const [hoverItem, setHoverItem] = useState(-1);

  // 追加請求かどうか？
  const [isAddInvoice, setAddInvoice] = useState(location?.state?.addInvoice);

  // Grid初期化処理
  const initializedGrid = (flex) => {
    flex.mergeManager = new CustomMergeManager();
  };

  // 初期表示
  useEffect(() => {
    let seikyuNo = location?.state?.seikyuNo;
    let quoteNo = location?.state?.quoteNo;

    // 処理モード
    setMode(location?.state?.mode);
    // 戻り先
    setBackPage(location?.state?.backPage);

    // 空行を登録(見た目のため)
    setViewQuoteDetails([...Array(13).map((_) => {})]);

    if (location?.state?.mode == 'add') {
      // 新規追加モードの場合は、工事場所に住所を設定
      restApi.get(`/api/construction/${seikyuNo}`, (data) => {
        // 顧客情報
        setClient(data.client);
        // 工事情報
        setConstraction(data.construction);
        // 見積情報
        setQuoteHeader({
          ...quoteHeader,
          KOUJI_BASYO: data.client.PREF + data.client.CITY + data.client.TOWN + data.client.ADDRESS,
        });
        setAddInvoice(location?.state?.addInvoice);
      });
    } else {
      // 新規追加モード以外はDBから見積もり情報を取得する
      restApi.get(`/api/quote/${quoteNo}`, (data) => {
        // コピーの場合は新規になるのでキーを削除
        if (location?.state?.mode == 'cpy') {
          delete data.headar.QUOTE_NO;
          data.headar.MITSU_DATE = format(new Date(), 'yyyy/MM/dd');
          data.headar.KOUKI = '';
          // 価格再計算フラグは複写時はクリア
          data.details = data.details.map((x) => ({ ...x, KAKAKU_UPD_FLG: 0 }));
        }
        setQuoteHeader(data.headar);
        setQuoteDetails(data.details);
        setClient(data.client);
        setConstraction(data.constraction);
        setAddInvoice(data.headar.MOTO_KEI_NO == 1);
      });
    }
  }, []);

  // 明細登録ボタン押下処理
  const handleOnClickDetailRegist = () => {
    // 見積基盤モーダルにパラメタを渡す
    setParamBaseQuote({
      Mode: 'add',
      KojiSyu: Array.from(
        new Set(quoteDetails.filter((x) => x && 'CONTENT' in x).map((x) => x['KOUJI_SYU']))
      ),
      Details: [],
      IsInit: quoteDetails.length == 0, // 初回の登録かどうか？
      isAddInvoice: isAddInvoice,
      shokei: quoteDetails.reduce((a, x) => a + x['KINGAKU'], 0),
      genkaKei: quoteDetails.reduce((a, x) => a + x['GENKA_KINGAKU'], 0),
    });
    setShow(true);
  };

  // 明細編集ボタン押下処理
  const handleOnClickDetailEdit = (item) => (e) => {
    // 見積基盤モーダルにパラメタを渡す
    setParamBaseQuote({
      Mode: 'edit',
      KojiSyu: Array.from(
        new Set(quoteDetails.filter((x) => x && 'CONTENT' in x).map((x) => x['KOUJI_SYU']))
      ),
      TargetKojiSyu: item['KOUJI_SYU'],
      Details: quoteDetails.filter((x) => x['KOUJI_SYU'] == item['KOUJI_SYU']), // 対象の工事種の明細情報
      isAddInvoice: isAddInvoice,
      shokei: quoteDetails
        .filter((x) => x['KOUJI_SYU'] != item['KOUJI_SYU'])
        .reduce((a, x) => a + x['KINGAKU'], 0),
      genkaKei: quoteDetails
        .filter((x) => x['KOUJI_SYU'] != item['KOUJI_SYU'])
        .reduce((a, x) => a + x['GENKA_KINGAKU'], 0),
    });
    setShow(true);
  };
  // 明細移動ボタン押下処理
  const handleOnClickDetailMove = (item, upDown) => (e) => {
    // 下に移動
    if (upDown == 'down') {
      // 現在値を取得
      let idx = quoteDetails.indexOf(quoteDetails.find((x) => x['KOUJI_SYU'] == item['KOUJI_SYU']));
      // 次の工事種を取得
      let next_koujiSyu = quoteDetails.slice(idx).find((x) => x['KOUJI_SYU'] != item['KOUJI_SYU'])[
        'KOUJI_SYU'
      ];

      // その他（諸経費）は常に一番下
      if (next_koujiSyu == 'その他（諸経費）') {
        return;
      }

      // 対象を取得
      let targets = [...quoteDetails.filter((x) => x['KOUJI_SYU'] == item['KOUJI_SYU'])];
      // 対象を削除
      let _quoteDetails = [...quoteDetails.filter((x) => x['KOUJI_SYU'] != item['KOUJI_SYU'])];
      _quoteDetails.splice(
        idx + quoteDetails.filter((x) => x['KOUJI_SYU'] == next_koujiSyu).length,
        0,
        ...targets
      );
      setQuoteDetails(_quoteDetails);
    }
    // 上に移動
    else {
      // 現在値を取得
      let idx = quoteDetails.indexOf(quoteDetails.find((x) => x['KOUJI_SYU'] == item['KOUJI_SYU']));
      // 一番上の明細の場合は移動させない
      if (idx <= 0) {
        return;
      }
      // 前の工事種を取得
      let before_koujiSyu = quoteDetails[idx - 1]['KOUJI_SYU'];
      // 対象を取得
      let targets = [...quoteDetails.filter((x) => x['KOUJI_SYU'] == item['KOUJI_SYU'])];
      // 対象を削除
      let _quoteDetails = [...quoteDetails.filter((x) => x['KOUJI_SYU'] != item['KOUJI_SYU'])];
      _quoteDetails.splice(
        idx - quoteDetails.filter((x) => x['KOUJI_SYU'] == before_koujiSyu).length,
        0,
        ...targets
      );
      setQuoteDetails(_quoteDetails);
    }
  };
  // 明細削除ボタン押下処理
  const handleOnClickDetailDelete = (item) => (e) => {
    // 対象を削除
    let _quoteDetails = [...quoteDetails.filter((x) => x['KOUJI_SYU'] != item['KOUJI_SYU'])];

    setQuoteDetails(_quoteDetails);
  };

  // 明細データに変更があった時の処理
  useEffect(() => {
    let _viewQuoteDetails = [];
    let _kojiSyu = '';

    quoteDetails.forEach((qd, i) => {
      // 明細の小計行を作成
      if (_kojiSyu != qd['KOUJI_SYU']) {
        _kojiSyu = qd['KOUJI_SYU'];
        _viewQuoteDetails.push({
          Group: true,
          Deletable: !quoteDetails.some((x) => x.KOUJI_SYU == qd.KOUJI_SYU && x.CYU_MNO_KEY), // 注文がない場合は削除可能
          KOUJI_SYU: qd['KOUJI_SYU'],
          CONTENT: qd['KOUJI_SYU'],
          KINGAKU: quoteDetails
            .filter((x) => x['KOUJI_SYU'] == qd['KOUJI_SYU'])
            .reduce((a, x) => a + x['KINGAKU'], 0),
          GENKA_KINGAKU: quoteDetails
            .filter((x) => x['KOUJI_SYU'] == qd['KOUJI_SYU'])
            .reduce((a, x) => a + x['GENKA_KINGAKU'], 0),
        });
      }
      // 明細を追加
      _viewQuoteDetails.push(qd);
    });

    setViewQuoteDetails([..._viewQuoteDetails]);

    // 小計
    let shokei = quoteDetails.reduce((a, x) => a + x['KINGAKU'], 0);
    // 消費税
    let tax = Math.floor(shokei * 0.1);
    // 請求額
    let seikyu = shokei + tax;
    // 原価合計
    let genka_kingaku = quoteDetails.reduce((a, x) => a + x['GENKA_KINGAKU'], 0);
    // 粗利
    let arari = shokei - genka_kingaku;
    // 粗利率
    let arariritu = Math.floor((arari / shokei) * 100) / 100;

    // 集計結果を反映
    setQuoteTotal([
      {
        ShoKei: shokei,
        ZeiKei: tax,
        SeikyuKei: seikyu,
        GenkaKei: genka_kingaku,
        ArariKei: arari,
        RiekiRitsu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);

    // 見積ヘッダに小計と税を設定
    setQuoteHeader({ ...quoteHeader, MITSU_KIN: shokei, ZEI: tax, GENKA_TOTAL: genka_kingaku });
  }, [quoteDetails, displayInfo]);

  // 見積明細モーダルからのコールバック処理
  const callbakQuoteDetaiModal = (details, orgKoujiSyu) => {
    let _quoteDetails = [...quoteDetails];

    // 対象更新対象をフィルタ
    _quoteDetails = _quoteDetails.filter((x) => x['KOUJI_SYU'] != orgKoujiSyu);

    // 更新前の位置取得
    let idx = quoteDetails.indexOf(quoteDetails.find((x) => x['KOUJI_SYU'] == orgKoujiSyu));
    if (idx >= 0) {
      // 更新の場合は本来の位置に追加
      _quoteDetails.splice(idx, 0, ...details);
    } else {
      // 追加の場合は末尾に追加
      _quoteDetails = [..._quoteDetails, ...details];
    }

    // その他（諸経費）は明細の最後
    _quoteDetails.sort(
      (a, b) =>
        (a['KOUJI_SYU'] == 'その他（諸経費）' ? 1 : 0) -
        (b['KOUJI_SYU'] == 'その他（諸経費）' ? 1 : 0)
    );

    setQuoteDetails(_quoteDetails);
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;
      // 集計行の場合
      if (dataItem && dataItem['Group']) {
        e.cell.style.background = isAddInvoice ? 'rgb(63, 130, 243)' : 'rgb(255, 160, 122)'; // 追加請求と見積もりで色を変更
        e.cell.style.color = 'rgb(255, 255, 255)';
        // 明細行の場合
      } else {
        e.cell.style.background = '';
        e.cell.style.color = dataItem.KAKAKU_UPD_FLG ? '#150AF5' : '';
        e.cell.style.fontWeight = dataItem.KAKAKU_UPD_FLG ? 'bold' : '';
      }
    }
  };

  // 編集処理
  const handleHeaderChange = (prop) => (target) => {
    setQuoteHeader({ ...quoteHeader, [prop]: target.value });
  };

  // 保存処理
  const handleOnClickSave = (_, reject = null) => {
    let _caption = reject ? '却下' : action[mode]['caption'];
    let _cmd = reject ? 'upd' : action[mode]['cmd'];
    setDialog({
      type: 'confirm',
      message: `${_caption}します。よろしいですか？`,
      okCallBack: () => {
        if (_cmd == 'del') {
          handleOnClickDelete();
        } else {
          handleOnClickUpdate(quoteDetails, reject);
        }
      },
    });
  };

  // ステータス取得
  const getStatus = (_mode) => {
    switch (_mode) {
      case 'approval':
        return '承認済み';
      case 'los':
        return '失注';
      case 'request':
        return '承認待ち';
      case 'cancel':
        return '作成中';
      case 'reject':
        return '承認却下';
      case 'add':
        return '作成中';
      case 'upd':
        return quoteHeader['STATUS'] == '承認待ち' ? '承認待ち' : '作成中';
      default:
        return '';
    }
  };

  // 更新処理
  const handleOnClickUpdate = (details, reject = null) => {
    let _mode = reject ? 'reject' : mode;
    let _quoteHeader = {
      ...quoteHeader,
      STATUS: getStatus(_mode),
      SEIKYU_NO: location?.state?.seikyuNo,
      MOTO_KEI_NO: isAddInvoice ? 1 : 0, // 追加請求の場合は元契約番号に1を設定
    };

    let req = {
      client: { CLIENT_CD: client.CLIENT_CD },
      header: _quoteHeader,
      details: details.map((x, i) => ({
        QUOTE_MNO: i + 1,
        QUOTE_MNO_KEY: x.QUOTE_MNO_KEY ?? '',
        BASE_NO: x.BASE_NO,
        KOUJI_SYU: x.KOUJI_SYU,
        CONTENT: x.CONTENT,
        SIYOU: x.SIYOU,
        SU: x.SU,
        TANI: x.TANI,
        TANKA: x.TANKA ?? '',
        KINGAKU: x.KINGAKU,
        BIKO: x.BIKO,
        BIKO_SYANAI: x.BIKO_SYANAI,
        GENKA_RATE: x.GENKA_RATE ?? '',
        GENKA_TANKA: x.GENKA_TANKA ?? '',
        GENKA_KINGAKU: x.GENKA_KINGAKU,
        EDITABLE_FLG: x.EDITABLE_FLG,
        KAKAKU_UPD_FLG: x.KAKAKU_UPD_FLG ?? 0,
      })),
    };

    let _cmd = reject ? 'upd' : action[mode]['cmd'];
    restApi.post(`/api/quote/${_cmd}`, req, (data) => {
      navigate(backPage, {
        state: {
          ...location.state,
          clientId: client.CLIENT_CD,
          quoteNo: data.QUOTE_NO,
        },
      });
    });
  };

  // 削除・失注処理
  const handleOnClickDelete = () => {
    restApi.post(
      `/api/quote/${action[mode]['cmd']}`,
      {
        client: { CLIENT_CD: client.CLIENT_CD },
        header: quoteHeader,
      },
      (data) => {
        navigate(backPage, {
          state: {
            ...location.state,
            clientId: client.CLIENT_CD,
          },
        });
      }
    );
  };

  // 戻るボタン
  const handleOnClickReturn = () => {
    navigate(backPage, {
      state: {
        ...location.state,
        clientId: client.CLIENT_CD,
      },
    });
  };

  // 見積基盤再計算モーダルを開く
  const [reCalcModalParam, setReCalcModalParam] = useState({});
  const handleOnClickReCalc = () => {
    setReCalcModalParam({
      show: true,
      quoteDetails: quoteDetails,
      callback: (detatils) => {
        setQuoteDetails(detatils);
      },
    });
  };

  return (
    <>
      <Card className="m-0">
        <Card.Body>
          <Row
            onClick={() => {
              setDisplayInfo(!displayInfo);
            }}
          >
            <Col style={{ display: 'flex', columnGap: '15px' }}>
              <Label
                style={{ fontSize: '120%', fontWeight: 'bold', color: 'black' }}
              >{`${client.NAME_SEI_1} ${client.NAME_MEI_1}`}</Label>
              {mode == 'add' || mode == 'cpy' ? (
                <StatusLableComponent status={'新規'} />
              ) : (
                <StatusLableComponent status={quoteHeader.STATUS} />
              )}
            </Col>
            <Col className="text-right">
              <div className="font-icon-detail">
                {displayInfo ? (
                  <i className="material-symbols-rounded">keyboard_control_key</i>
                ) : (
                  <i className="material-symbols-rounded">keyboard_arrow_right</i>
                )}
              </div>
            </Col>
          </Row>
          {displayInfo ? (
            <>
              <Row>
                <Col className="pr-1" md="3">
                  <Form.Group>
                    <label>工事名</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={constraction?.KOUJI_MEI}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="4">
                  <Form.Group>
                    <label>工事場所</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={constraction?.KOUJI_BASYO}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                {isAddInvoice ? (
                  <></>
                ) : (
                  <>
                    <Col className="pr-1" md="2">
                      <Form.Group>
                        <label>工期</label>
                        <TextComponent
                          type="text"
                          value={quoteHeader?.KOUKI}
                          onChange={handleHeaderChange('KOUKI')}
                          disabled={action[mode].readOnly}
                        ></TextComponent>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                      <Form.Group>
                        <label>有効期間</label>
                        <TextComponent
                          placeholder=""
                          type="text"
                          value={quoteHeader?.MITSU_KIGEN}
                          onChange={handleHeaderChange('MITSU_KIGEN')}
                          disabled={action[mode].readOnly}
                        ></TextComponent>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <Form.Group>
                    <label>社内呼称</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={quoteHeader?.SYANAI_MEI}
                      onChange={handleHeaderChange('SYANAI_MEI')}
                      disabled={action[mode].readOnly}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>図面番号</label>
                    <TextComponent
                      type="text"
                      value={quoteHeader?.ZUMEN_NO}
                      onChange={handleHeaderChange('ZUMEN_NO')}
                      disabled={action[mode].readOnly}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>{isAddInvoice ? '作成日' : '見積日'}</label>
                    <TextComponent
                      type="date"
                      value={quoteHeader?.MITSU_DATE}
                      onChange={handleHeaderChange('MITSU_DATE')}
                      disabled={action[mode].readOnly}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                {isAddInvoice ? (
                  <></>
                ) : (
                  <Col className="pr-1" md="1">
                    <Form.Group>
                      <label>担当プランナー</label>
                      <SelectComponent
                        value={quoteHeader?.TAN_CD}
                        onChange={handleHeaderChange('TAN_CD')}
                        disabled={action[mode].readOnly}
                      >
                        <option value={''}></option>
                        {master.planer.map((x, i) => (
                          <option key={i} value={x['id']}>
                            {x['name']}
                          </option>
                        ))}
                      </SelectComponent>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="pr-1" md="6" rows="3">
                  <Form.Group>
                    <div className="mb-3">
                      <label htmlFor="textArea1" className="form-label">
                        備考
                      </label>
                      <TextComponent
                        className="form-control"
                        type="textarea"
                        id="textArea1"
                        rows="2"
                        value={quoteHeader?.BIKO}
                        onChange={handleHeaderChange('BIKO')}
                        disabled={mode == 'del'}
                      ></TextComponent>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <div className="mb-3">
                      <label htmlFor="textArea2" className="form-label">
                        社内備考（※見積書には印刷されません）
                      </label>
                      <TextComponent
                        className="form-control"
                        type="textarea"
                        rows="2"
                        value={quoteHeader?.BIKO_SYANAI}
                        onChange={handleHeaderChange('BIKO_SYANAI')}
                        disabled={mode == 'del'}
                      ></TextComponent>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-0">
        <Card.Body>
          <Row>
            <Col>工事見積明細</Col>
            <Col className="text-right">
              {mode == 'cpy' && (
                <>
                  <Button
                    variant="success"
                    className="btn-fill mr-2 recalc_button"
                    title="見積基盤から再計算"
                    onClick={handleOnClickReCalc}
                  >
                    見積基盤から再計算
                  </Button>
                </>
              )}
              {!action[mode].readOnly && (
                <Button
                  className="material-symbols-rounded cell-icon-sm btn-fill"
                  title="明細登録"
                  onClick={handleOnClickDetailRegist}
                >
                  add
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              className="pl-3 table-scroll"
              md="12"
              style={
                displayInfo ? { height: 'calc(100vh - 498px)' } : { height: 'calc(100vh - 255px)' }
              }
            >
              <FlexGrid
                allowSorting={false}
                ref={flexGrid}
                itemsSource={viewQuoteDetails}
                isReadOnly={true}
                selectionMode={'None'}
                headersVisibility={'Column'}
                initialized={initializedGrid.bind(this)}
                formatItem={formatItem}
                loadedRows={(grid) => {
                  grid.rows.forEach((ele) => {
                    ele.height = 24;
                  });
                }}
              >
                <FlexGridColumn binding="" header="名称" isReadOnly={true} width={200}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      cell.item && cell.item['Group'] && !action[mode].readOnly ? (
                        <div
                          style={{ width: '500px' }}
                          onMouseEnter={() => setHoverItem(cell.item)}
                          onMouseLeave={() => setHoverItem(-1)}
                        >
                          <Button
                            className="material-symbols-rounded cell-icon-sm btn-fill"
                            variant="primary"
                            title="編集"
                            onClick={handleOnClickDetailEdit(cell.item)}
                          >
                            Edit
                          </Button>
                          <span className="text-nowrap pl-1">
                            {cell.item ? cell.item['CONTENT'] : ''}
                          </span>
                          {cell.item['KOUJI_SYU'] != 'その他（諸経費）' &&
                          hoverItem == cell.item ? (
                            <>
                              <Button
                                className="material-symbols-rounded cell-icon-sm btn-fill ml-1"
                                variant="success"
                                title="下に移動"
                                onClick={handleOnClickDetailMove(cell.item, 'down')}
                              >
                                Arrow_Downward
                              </Button>
                              <Button
                                className="material-symbols-rounded cell-icon-sm btn-fill ml-1"
                                variant="success"
                                title="上に移動"
                                onClick={handleOnClickDetailMove(cell.item, 'up')}
                              >
                                Arrow_Upward
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <>{cell.item ? cell.item['CONTENT'] : ''}</>
                      )
                    }
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="SIYOU"
                  header="仕様"
                  isReadOnly={true}
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SU"
                  header="数量"
                  format="n1"
                  width={60}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANI"
                  header="単位"
                  isReadOnly={true}
                  width={45}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANKA"
                  header="単価"
                  format="c0"
                  isReadOnly={true}
                  width={80}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="KINGAKU"
                  header="金額"
                  format="c0"
                  width={100}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn binding="BIKO" header="摘要" width={100}></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_RATE"
                  header="掛け率"
                  format="n2"
                  width={60}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_TANKA"
                  header="原価単価"
                  format="c0"
                  isReadOnly={true}
                  width={80}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_KINGAKU"
                  header="原価合計"
                  format="c0"
                  isReadOnly={true}
                  width={100}
                  dataType="Number"
                  allowResizing={false}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding=""
                  header=""
                  format="c0"
                  isReadOnly={true}
                  width={28}
                  allowResizing={false}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      cell.item && cell.item['EDITABLE_FLG'] == '1' ? (
                        <i
                          className="material-symbols-rounded p-1"
                          style={{ transform: 'scale(1.6)', color: 'green', background: '#DDFFDD' }}
                        >
                          Done
                        </i>
                      ) : (
                        <></>
                      )
                    }
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="BIKO_SYANAI"
                  header="社内備考"
                  width="2*"
                  allowResizing={false}
                ></FlexGridColumn>
                <FlexGridColumn binding="" header="　" isReadOnly={true} width={28}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      cell.item && cell.item['Group'] && !action[mode].readOnly ? (
                        <IconButtonCompnent
                          className="cell-icon-sm"
                          variant="danger"
                          title="削除"
                          onClick={handleOnClickDetailDelete(cell.item)}
                          disabled={!cell.item.Deletable}
                          tooltip={
                            cell.item.Deletable ? '' : '注文明細が存在するため、削除できません。'
                          }
                          icon="Clear"
                        ></IconButtonCompnent>
                      ) : (
                        <></>
                      )
                    }
                  />
                </FlexGridColumn>
              </FlexGrid>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <Row>
                <Col className="d-flex align-items-end">
                  <Button
                    className="footer-button"
                    variant="secondary"
                    onClick={handleOnClickReturn}
                  >
                    戻る
                  </Button>
                </Col>
                <Col>
                  <Row className="pr-3 text-right">
                    <FlexGrid
                      itemsSource={quoteTotal}
                      selectionMode={'None'}
                      headersVisibility={'Column'}
                      isReadOnly={true}
                      allowSorting={false}
                    >
                      <FlexGridColumn
                        binding="ShoKei"
                        header="小計"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="ZeiKei"
                        header="消費税"
                        format="c0"
                        width={90}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="SeikyuKei"
                        header="請求金額"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="GenkaKei"
                        header="原価合計"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="ArariKei"
                        header="粗利益額"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="RiekiRitsu"
                        header="利益率"
                        format="n2"
                        width={80}
                      ></FlexGridColumn>
                    </FlexGrid>
                  </Row>
                </Col>
                <Col className="d-flex align-items-end flex-row-reverse">
                  {mode == 'approval' ? (
                    <Button
                      variant={'danger'}
                      className="ml-2 btn-fill footer-button"
                      onClick={(e) => handleOnClickSave(e, 'reject')}
                    >
                      却下
                    </Button>
                  ) : null}
                  <Button
                    variant={action[mode]['variant']}
                    className="btn-fill footer-button"
                    onClick={handleOnClickSave}
                  >
                    {action[mode]['caption']}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <MessageModal dialog={dialog}></MessageModal>
      {/* 見積もり基盤モーダル */}
      <QuoteBaseModal
        show={show}
        setShow={setShow}
        paramBaseQuote={paramBaseQuote}
        callbakQuoteDetaiModal={callbakQuoteDetaiModal}
      ></QuoteBaseModal>
      {/* 再計算モーダル */}
      <ReCalcModal params={reCalcModalParam} quoteTotal={quoteTotal}></ReCalcModal>
    </>
  );
}

export default CustomerQuoteEntry;
