import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import StatusLableComponent from 'components/Input/StatusLableComponent';

function QuoteInfo(props) {
  const auth = useAuth();
  const restApi = useRestApi();
  const location = useLocation();
  const navigate = useNavigate();

  // パラメタ
  const quoteList = props.quoteList; // 見積り一覧
  const selectedSeikyuNo = props.selectedSeikyuNo; // 請求NO
  const setContractModalParam = props.setContractModalParam; // 契約ダイアログのパラメタ
  const clientInfo = props.clientInfo; // 顧客情報
  const contractList = props.contractList; // 契約一覧
  const seikyuInfo = props.seikyuInfo; // 選択中の工事情報

  // グリッド
  const quoteGrid = useRef();

  // 契約済以外も表示のチェックボックス
  const [checkedDisplayNotContract, setCheckedDisplayNotContract] = useState(false);
  // 表示中の見積もり一覧
  const [viewQuoteList, setViewQuoteList] = useState(quoteList);

  // 見積一覧、契約以外表示チェックボックスの変更処理
  useEffect(() => {
    // 契約済以外を表示/非表示を制御
    let _viewQuoteList = [
      ...quoteList.filter(
        (x) => contractList.length == 0 || checkedDisplayNotContract || x.STATUS == '契約済'
      ),
    ];
    setViewQuoteList(_viewQuoteList.map((x, i) => ({ ...x, NO: i + 1 })));
  }, [quoteList, checkedDisplayNotContract]);

  // 見積の新規作成
  const handleOnClickNewQuote = () => {
    navigate('/admin/CustomerQuoteEntry', {
      state: {
        mode: 'add',
        clientId: location?.state?.clientId,
        seikyuNo: selectedSeikyuNo,
        quoteNo: 0,
        backPage: location?.pathname,
      },
    });
  };

  // 見積編集・削除
  const handleOnClickEditQuote = (mode, item) => {
    navigate('/admin/CustomerQuoteEntry', {
      state: {
        mode: mode,
        clientId: location?.state?.clientId,
        seikyuNo: selectedSeikyuNo,
        quoteNo: item.QUOTE_NO,
        backPage: location?.pathname,
      },
    });
  };

  // 見積書の印刷
  const handleOnClickQuotePrint = (item) => {
    restApi.getToPdf(`/api/quote/report/${item.QUOTE_NO}`);
  };

  // 見積書のダウンロード
  const handleOnClickQuoteDownload = (item) => {
    restApi.postDownload(`/api/quote/download/${item.QUOTE_NO}`, {}, '見積書.xlsx', () => {});
  };

  // 契約作成・新規
  const handleOnClickEditContract = (item) => {
    setContractModalParam({
      mode: 'add',
      client: {
        CLIENT_CD: clientInfo['CLIENT_CD'],
        CLIENT_NAME: `${clientInfo['NAME_SEI_1']} ${clientInfo['NAME_MEI_1']}`,
        CLIENT_ADD: `${clientInfo['PREF']}${clientInfo['CITY']}${clientInfo['TOWN']}${clientInfo['ADDRESS']}`,
      },
      quote: item,
    });
  };

  // 見積もり明細のアイコンが使用不可かどうか
  const isQuoteIconDisabled = (icon, item) => {
    switch (icon) {
      case '編集':
      case '削除':
        // 契約済の場合は使用不可
        return contractList?.length > 0;
      case '契約書作成':
      case '変更契約書作成':
        // 承認済でない、または、契約済の場合は使用不可
        return item.STATUS != '承認済み' || contractList?.length > 0;
      case '承認申請':
        return item.STATUS == '承認待ち' || item.STATUS == '承認済み' || contractList?.length > 0;
      case '承認申請取消':
        return item.STATUS != '承認待ち';
      case 'コピー':
        // 契約済の場合はコピー不可
        return contractList?.length > 0;
      case '印刷':
        // 常に使用可能
        return false;
    }
  };

  // ステータスの既読処理
  const handleOnClickStatusLabel = (item) => {
    if (item.APP_UNREAD_FLG == 1) {
      restApi.post(
        '/api/client/appread',
        {
          TABLE: 'F_QUOTE_H',
          NO: item.QUOTE_NO,
        },
        (_) => {}
      );
    }
    item.APP_UNREAD_FLG = 0;
  };

  // 見積一覧のグリッドのフォーマット処理
  const quoteListFormatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;

      // 申請承認画面から呼び出された場合に選択した見積の場合に青くする
      let isUpd = location.state?.quoteNo == dataItem.QUOTE_NO;

      e.cell.style.color = isUpd ? 'blue' : '';
      e.cell.style.fontWeight = isUpd ? 'bold' : '';
      e.cell.style.background = isUpd ? '#DDEEFF' : '';
    }
  };

  // 優先アイコンの選択処理
  const handleOnClickPriority = (item) => {
    // 契約済みの場合は優先度の変更はできない
    if (contractList?.length > 0 && quoteList.some((x) => x.STATUS != '契約済')) {
      console.log('なし');
      return;
    }
    restApi.post(
      '/api/quote/priority',
      {
        SEIKYU_NO: seikyuInfo?.SEIKYU_NO,
        QUOTE_NO: item.QUOTE_NO,
      },
      (_) => {
        props.searchSeikyu(seikyuInfo?.CLIENT_CD, seikyuInfo.SEIKYU_NO);
      }
    );
  };

  return (
    <>
      <Row>
        <Col>
          <div className="list-label1">見積り一覧</div>
        </Col>
        <Col></Col>
        <Row>
          <Col className="mr-3 pt-0 d-flex flex-nowrap text-right align-items-end">
            {contractList?.length > 0 && quoteList.some((x) => x.STATUS != '契約済') ? (
              <label className="mr-2" style={{ fontSize: '14pt', color: 'black' }}>
                <input
                  className="mr-2"
                  style={{ transform: 'scale(1.5)' }}
                  type="checkbox"
                  checked={checkedDisplayNotContract}
                  onChange={() => setCheckedDisplayNotContract(!checkedDisplayNotContract)}
                ></input>
                契約済以外も表示
              </label>
            ) : (
              <></>
            )}
            <Button
              className="material-symbols-rounded cell-icon btn-fill"
              variant="primary"
              onClick={handleOnClickNewQuote}
              disabled={selectedSeikyuNo == -1 || contractList?.length > 0}
              title="見積作成"
            >
              add
            </Button>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col>
          <div className="list-3-line">
            <FlexGrid
              ref={quoteGrid}
              itemsSource={viewQuoteList}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              loadedRows={(grid) => {
                grid.rows.forEach((ele) => {
                  ele.height = 38;
                });
              }}
              formatItem={quoteListFormatItem}
            >
              <FlexGridColumn binding="NO" header="No" width={60} align={'right'}></FlexGridColumn>
              <FlexGridColumn
                binding="QUOTE_NO"
                header="見積番号"
                format={'d'}
                width={95}
              ></FlexGridColumn>
              <FlexGridColumn binding="MITSU_DATE" header="見積り日" width={95}></FlexGridColumn>
              <FlexGridColumn
                binding="SYANAI_MEI"
                header="工事名（社内呼称）"
                width="*"
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="MITSU_KIN_ZEI"
                header="金額(税込)"
                width={100}
                align={'right'}
              ></FlexGridColumn>
              <FlexGridColumn binding="STATUS" header="ステータス" width={88} align="center">
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <StatusLableComponent
                      status={cell.item?.STATUS}
                      isUnread={cell.item?.APP_UNREAD_FLG == 1}
                      onClick={() => handleOnClickStatusLabel(cell.item)}
                    />
                  )}
                />
              </FlexGridColumn>
              <FlexGridColumn binding="STATUS" header="本命" width={60} align="center">
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) =>
                    cell.item.QUOTE_NO == seikyuInfo?.FORECASTED_QUOTE_NO ? (
                      <StarIcon color="warning" />
                    ) : (
                      <div
                        onClick={() => handleOnClickPriority(cell.item)}
                        style={{ cursor: 'pointer' }}
                      >
                        <StarBorderIcon color="disabled" />
                      </div>
                    )
                  }
                />
              </FlexGridColumn>
              <FlexGridColumn header="" binding="" isReadOnly={true} width={258}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <span>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="primary"
                        title="編集"
                        onClick={() => handleOnClickEditQuote('upd', cell.item)}
                        disabled={isQuoteIconDisabled('編集', cell.item)}
                      >
                        edit
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="danger"
                        title="削除"
                        onClick={() => handleOnClickEditQuote('del', cell.item)}
                        disabled={isQuoteIconDisabled('削除', cell.item)}
                      >
                        delete
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="primary"
                        title="印刷"
                        onClick={() => handleOnClickQuotePrint(cell.item)}
                        disabled={isQuoteIconDisabled('印刷', cell.item)}
                      >
                        print
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="primary"
                        title="コピー"
                        onClick={() => handleOnClickEditQuote('cpy', cell.item)}
                        disabled={isQuoteIconDisabled('コピー', cell.item)}
                      >
                        file_copy
                      </Button>
                      <Button
                        className="cell-icon btn-fill"
                        variant="primary"
                        title="承認申請"
                        onClick={() => handleOnClickEditQuote('request', cell.item)}
                        disabled={isQuoteIconDisabled('承認申請', cell.item)}
                      >
                        申
                      </Button>
                      <Button
                        className="cell-icon btn-fill"
                        variant="danger"
                        title="承認申請取消"
                        onClick={() => handleOnClickEditQuote('cancel', cell.item)}
                        disabled={isQuoteIconDisabled('承認申請取消', cell.item)}
                      >
                        消
                      </Button>
                      <Button
                        className="cell-icon btn-fill"
                        variant="primary"
                        title="契約書作成"
                        onClick={() => handleOnClickEditContract(cell.item)}
                        disabled={isQuoteIconDisabled('契約書作成', cell.item)}
                      >
                        契
                      </Button>
                    </span>
                  )}
                />
              </FlexGridColumn>
              {auth.auth.kbn?.some((k) => ['admin'].includes(k)) && (
                <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="material-symbols-rounded cell-icon btn-fill"
                          variant="success"
                          title="ダウンロード"
                          onClick={() => handleOnClickQuoteDownload(cell.item)}
                          disabled={isQuoteIconDisabled('印刷', cell.item)}
                        >
                          download
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
              )}
            </FlexGrid>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default QuoteInfo;
